import { breakpoints } from '../helpers/variables';

class Anchor {
  breakpoint = window.matchMedia(
    `(max-width: ${breakpoints.tabletLandscape - 1}px)`
  );

  constructor() {
    if (window.location.hash) {
      const hash = window.location.hash;
      this.scrollToAnchor(hash);
    }

    this.handleLinkClicks();
  }

  handleLinkClicks() {
    const links = document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]');

    links.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        const targetId = link.getAttribute('href');
        this.scrollToAnchor(`${targetId}`);
      });
    });
  }

  scrollToAnchor(anchor: string) {
    console.log(anchor);
    const targetElement = document.querySelector(anchor);
    if (targetElement) {
      const offset = this.breakpoint.matches ? 95 : 170;
      const targetPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = targetPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
}

export default Anchor;
